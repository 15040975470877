import Alpine from 'alpinejs'
import focus from '@alpinejs/focus'

import Splide from '@splidejs/splide';
import "@splidejs/splide/css";

import './animations.ts'

window.Splide = Splide as any
window.Alpine = Alpine

Alpine.plugin(focus)
Alpine.start()
