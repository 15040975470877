import { animate, scroll, inView, stagger } from "motion"

document.querySelectorAll(".fade-in-out").forEach((item) => {
  scroll(animate(item, { opacity: [0, 1, 1, 0] }), {
    target: item,
    offset: ["start end", "end end", "start start", "end start"]
  });
});

document.querySelectorAll("[data-speed]").forEach((item) => {
  const speed = parseFloat(item.getAttribute('data-speed')) || 100;
  scroll(animate(item, { y: [-(speed), speed] }), {
    target: item,
    offset: ["end start", "start end"],
  });
});

inView("[data-appear]", ({ target }) => {
  animate(
    target,
    { opacity: 1, y: [100, 0],  transform: 'none' },
    { delay: 0.2, duration: 0.9, easing: [0.17, 0.55, 0.55, 1] }
  )
}, {
  amount: 0.40
})

// Gallery Images
const galleryImgs: NodeListOf<HTMLImageElement> = document.querySelectorAll("[data-gallery] img")
galleryImgs.forEach(img => img.style.opacity = '0')
inView("[data-gallery]", ({ target }) => {
  const imgs = target.querySelectorAll('img')
  animate(
    imgs,
    { opacity: 1, transform: 'none' },
    { duration: 0.9, easing: [0.17, 0.55, 0.55, 1], delay: stagger(0.15) }
  )
}, {
 amount: 0.40
})
